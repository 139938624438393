<template>
  <FRadioButton
    :model-value="mailer"
    :options="options"
    @change="onChange"
  />
</template>

<script>
import { computed } from 'vue';

import { setMailer } from '@/views/admin/AdminSettings/api';
import { useAdmin } from '@/composables/useAdmin';

export default {
  name: 'MailerSettings',
  setup() {
    const { adminSettings } = useAdmin();

    const options = [
      { text: 'Postmark', value: 'postmark' },
      { text: 'Mandrill', value: 'mandrill' },
    ];

    const mailer = computed(() => adminSettings.value.activeSystemMailer);

    const onChange = (value) => {
      setMailer(value.toLowerCase());
    };

    return {
      options,
      mailer,
      onChange,
    };
  },
};
</script>

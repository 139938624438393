<template>
  <div>
    <AppText variant="div" size="18px" class="font-medium">
      {{ title }}
    </AppText>
    <AppText variant="div" color="var(--color-939393)" mt="8px">
      {{ description }}
    </AppText>
  </div>
</template>

<script>
export default {
  name: 'SettingsHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="controls">
    <div class="item">
      <FSwitch
        v-model="isMaintenanceOn"
        is-small
        :loading="isMaintainceLoading"
        type="primary"
        @change="onMaintenanceChange"
      />
      <AppText :color="isMaintenanceOn ? 'var(--color-black)' : 'var(--color-939393)'">
        Maintenance Mode
      </AppText>
    </div>
    <AppTooltip
      :is-enabled="!isMerchantRegPossible"
      text-align="left"
      width="252px"
    >
      <template #text>
        Both Bitcoin and Litecoin pubkeys must be added in order to enable new merchant registrations.
      </template>

      <div class="item">
        <FSwitch
          :model-value="isSignUpOn"
          is-small
          type="primary"
          :loading="isSignupLoading"
          :disabled="!isMerchantRegPossible"
          @change="onSingUpChange"
        />
        <AppText :color="isSignUpOn ? 'var(--color-black)' : 'var(--color-939393)'">
          Merchant Registrations
        </AppText>
      </div>
    </AppTooltip>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';

import { toggleMaintenance, toggleSignUp } from '@/views/admin/AdminSettings/api';
import { useAdmin } from '@/composables/useAdmin';

export default {
  name: 'MasterControl',
  components: {
    AppTooltip,
  },
  setup() {
    const { adminSettings } = useAdmin();

    const isMaintenanceOn = computed(() => adminSettings.value.isMaintenanceEnabled);
    const isMaintainceLoading = ref(false);

    const isSignUpOn = computed(() => adminSettings.value.isSignUpEnabled);

    const isMerchantRegPossible = computed(() => Boolean(adminSettings?.value?.masterWallet?.BTC?.xpub && adminSettings?.value?.masterWallet?.LTC?.xpub) && !isMaintenanceOn.value);

    const onMaintenanceChange = async (value) => {
      isMaintainceLoading.value = true;
      await toggleMaintenance({ isActive: value });
      isMaintainceLoading.value = false;
    };

    const isSignupLoading = ref(false);
    const onSingUpChange = async (value) => {
      isSignupLoading.value = true;
      await toggleSignUp({ isActive: value });
      isSignupLoading.value = false;
    };

    return {
      isMaintenanceOn,
      isSignUpOn,
      onMaintenanceChange,
      onSingUpChange,
      isMerchantRegPossible,

      isMaintainceLoading,
      isSignupLoading,
    };
  },
};
</script>

<style scoped lang="scss">
.controls {
  .item {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  :deep(.f-switch.primary:not(.is-checked) .el-switch__core) {
    background: var(--color-black-005);
  }
}
</style>

<template>
  <div
    class="block-wrapper"
    :class="{ active: coin.isActive }"
    @click="onClick"
  >
    <div class="flex-center">
      <FamilyIcon
        v-if="!coin.isPrimary"
        size="24px"
        :is-disabled="!coin.isActive"
        class="coin-icon"
        :icon="`${coin.isActive ? coin.icon : coin.iconDisabled}`"
        :family-icon="coin.isActive ? coin.familyIcon : coin.familyIconDisabled"
      />

      <template v-else>
        <AppIcon v-show="coin.isActive" class="coin-icon" :name="coin.icon" size="24px" />
        <AppIcon v-show="!coin.isActive" class="coin-icon" :name="coin.iconDisabled" size="24px" />
      </template>
    </div>

    <AppText class="font-medium" :color="coin.isActive ? 'var(--color-black)' : 'var(--color-black-05)'">
      {{ coin.name }} <template v-if="!coin.isPrimary">
        ({{ coin.family }})
      </template>
    </AppText>

    <FSpacer />

    <slot name="icon">
      <AppIcon
        name="green-check-circle"
        size="16px"
        color="var(--color-white)"
        class="badge"
        :class="{ active: coin.isActive }"
      />
    </slot>
  </div>
</template>

<script setup>
import FamilyIcon from '@/components/Icon/FamilyIcon.vue';

const props = defineProps({
  coin: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['toggle']);

const onClick = () => {
  emit('toggle', props.coin.value, !props.coin.isActive);
};
</script>

<style scoped lang="scss">
.block-wrapper {
  width: 188px;
  height: 40px;

  border-radius: 8px;
  border: 1px solid var(--color-E1E1E1);

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  opacity: 1;

  padding: 8px 8px 8px 12px;

  @include transition-base;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    border-color: var(--color-primary);
    &:hover {
      opacity: 0.7;
    }
  }

  .coin-icon {
    margin-right: 12px;
    position: relative;
  }
}
</style>

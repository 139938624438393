<template>
  <TransitionWrapper>
    <div v-if="!isSettingsLoading">
      <TabsContainer
        v-model="currentTab"
        :options="tabs"
        height="50px"
      >
        <div class="settings-tabs">
          <SimpleTransition>
            <SettingsMaster v-if="currentTab === tabs[0].value" />

            <SettingsMonitors
              v-if="currentTab === tabs[1].value"
              :is-loading="isRatesLoading"
              :crypto="crypto"
              :forex="forex"
              :is-failed="isFiatFailed"
            />

            <AdminRules v-if="currentTab === tabs[2].value" />
            <AdminBilling v-if="isSuperAdminRole && currentTab === tabs[3].value" />
          </SimpleTransition>
        </div>
      </TabsContainer>
    </div>
  </TransitionWrapper>
</template>

<script>
import {
  computed, defineComponent, onBeforeMount, ref,
} from 'vue';

import SimpleTransition from '@/components/Animation/SimpleTransition.vue';
import TabsContainer from '@/components/Containers/TabsContainer.vue';
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { useBus } from '@/composables/useBus';
import { useToken } from '@/composables/useToken';

import AdminBilling from './AdminBilling.vue';
import SettingsMaster from './SettingsMaster';
import SettingsMonitors from './SettingsMonitors.vue';
import AdminRules from './AdminRules';
import { getAdminSettings, getRates } from './api';

export default defineComponent({
  name: 'AdminSettings',
  components: {
    TabsContainer,
    SimpleTransition,
    TransitionWrapper,

    SettingsMaster,
    SettingsMonitors,
    AdminRules,
    AdminBilling,
  },
  setup() {
    const { isSuperAdminRole } = useToken();

    const tabs = computed(() => [
      { text: 'MASTER', value: 'master' },
      { text: 'MONITORS', value: 'monitors' },
      { text: 'RULES', value: 'rules' },
      { text: 'BILLING', value: 'billing' },
    ].filter((tab) => {
      if (!isSuperAdminRole.value && tab.value === 'billing') {
        return null;
      }
      return tab;
    }).filter((el) => el));

    const currentTab = ref(tabs.value[0].value);

    const crypto = ref([]);
    const forex = ref([]);
    const isFiatFailed = ref({});

    const isSettingsLoading = ref(false);
    const isRatesLoading = ref(false);
    const getData = () => {
      getAdminSettings().then(() => {
        isSettingsLoading.value = false;
      });

      getRates().then(({ data }) => {
        crypto.value = data.crypto;
        forex.value = data.forex;
        isFiatFailed.value = data.isFailed;
        isRatesLoading.value = false;
      });
    };

    onBeforeMount(() => {
      isSettingsLoading.value = true;
      isRatesLoading.value = true;
      getData();
    });

    const { emitHandler } = useBus();
    emitHandler('update-settings-page', () => {
      isRatesLoading.value = true;
      getData();
    });

    return {
      tabs,
      currentTab,

      crypto,
      forex,
      isFiatFailed,
      isSettingsLoading,
      isRatesLoading,
      isSuperAdminRole,
    };
  },
});
</script>

<template>
  <div class="rules-item-wrap">
    <div class="rules-item">
      <AppIcon :name="icon" :opacity="0.4" size="18px" is-img-tag />
      <div class="info">
        <AppText variant="div">
          {{ title }}
        </AppText>
        <AppText v-if="description" variant="div" size="12px" :opacity="0.4">
          {{ description }}
        </AppText>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'RulesItem',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  emits: ['change'],
  setup(props) {
    const padding = computed(() => (props.description ? '30px 0' : '20px 0'));

    return { padding };
  },
};
</script>

<style scoped lang="scss">
.rules-item-wrap {
  height: 77px;
  display: flex;
  align-items: center;
  padding: 0 30px 0 20px;

  .rules-item {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 16px;

    .info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
</style>

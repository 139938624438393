<template>
  <div class="d-flex align-items-center justify-content-between">
    <AppText>Limit total active payments per merchant</AppText>

    <FSelect
      v-model="limit"
      style="width: 200px;"
      :options="options"
      no-margin
      size="x-small"
      has-arrow
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

import { useAdmin } from '@/composables/useAdmin';

import { onUpdateLimiterOption } from '../../api';

export default defineComponent({
  setup() {
    const { adminSettings } = useAdmin();

    const limit = ref(adminSettings.value.merchantActivePaymentLimit);

    const options = [
      { text: 'Disabled', value: 0 },
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
    ];

    watch(limit, () => {
      onUpdateLimiterOption({ limit: limit.value === 0 ? null : limit.value });
    });

    return { options, limit };
  },
});
</script>

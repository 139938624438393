<template>
  <div class="bill-settings">
    <SettingsHeader
      title="Business Information"
      description="As printed on invoices issued to merchants"
      style="margin-bottom: 30px;"
    />

    <div class="d-flex align-items-center" style="gap: 12px; margin-bottom: 30px;">
      <FSwitch
        v-model="printBillingLegalInfo"
        is-small
        type="primary"
        @change="onSwitch"
      />
      <AppText>Print future invoices with the following data</AppText>
    </div>

    <div style="margin-bottom: 20px;">
      <FInput
        v-model="name"
        label="Legal Name"
        :maxlength="25"
        :disabled="!printBillingLegalInfo"
      >
        <template #prefix>
          <AppIcon
            name="switch-logo"
            size="18px"
            fill="var(--color-black-04)"
          />
        </template>
      </FInput>
    </div>

    <FSelect
      v-model="country"
      label="Country of Incorporation"
      filterable
      has-arrow
      :options="countries"
      style="margin-bottom: 20px;"
      :disabled="!printBillingLegalInfo"
      @update:model-value="onCountryChange"
    >
      <template #prefix>
        <CountryFlag
          :key="countryCode"
          :value="countryCode"
          size="18px"
          :is-disabled="!printBillingLegalInfo"
        />
      </template>
      <template #option="{ item }">
        <div class="d-flex align-items-center">
          <CountryFlag :value="item.value" size="18px" style="margin-right: 12px;" /> {{ item.text }}
        </div>
      </template>
    </FSelect>

    <FButton @click="onSaveChanges">
      Save Changes
    </FButton>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import CountryFlag from '@/components/Icon/CountryFlag.vue';

import { useAdmin } from '@/composables/useAdmin';

import {
  getCountriesList,
  onUpdateBillingInfo,
  onUpdatePrintBillingLegalInfo,
} from './api';
import SettingsHeader from './SettingsHeader.vue';

const countries = ref([]);

onMounted(async () => {
  const { data } = await getCountriesList();
  countries.value = data;
});

const { adminSettings } = useAdmin();

const name = ref(adminSettings.value.name);
const country = ref(adminSettings.value.country);
const countryCode = ref(adminSettings.value.countryCode);
const printBillingLegalInfo = ref(adminSettings.value.printBillingLegalInfo);

const onCountryChange = (value) => {
  countryCode.value = value;
};

const onSwitch = (value) => {
  onUpdatePrintBillingLegalInfo(value);
};

const onSaveChanges = () => {
  onUpdateBillingInfo({
    name: name.value,
    country: countryCode.value,
  });
};
</script>

<style lang="scss" scoped>
.bill-settings {
  padding: 30px 0 0 40px;
  max-width: 400px;
}
</style>

<template>
  <div class="rules">
    <SettingsHeader
      title="Monitoring Transactions"
      description="Tune parameters for the monitoring service"
      style="margin-bottom: 20px;"
    />

    <div class="rules-items-wrap">
      <RulesItem
        class="expired-rule"
        icon="activity"
        title="Unconfirmed payments are marked expired after"
      >
        <FSelect
          v-model="paymentExpiredTime"
          class="rule-select-wrapper"
          style="width: 140px;"
          :options="expiredRuleOptions"
          has-arrow
          no-margin
          size="x-small"
          @change="onExpiredRuleChange"
        />
      </RulesItem>
    </div>

    <SettingsHeader
      title="Merchant Limitations"
      description="Customize rate limits or data ceilings for merchants"
      style="margin-bottom: 20px;"
    />

    <div class="rules-items-wrap">
      <RulesItem
        icon="users-stroke"
        title="Client limits"
        description="Applicable only to new registrations"
      >
        <div class="d-flex align-items-center">
          <FSelect
            v-model="maxDailyClientsAllowed"
            style="width: 140px;"
            :options="dailyOptions"
            has-arrow
            no-margin
            size="x-small"
            @change="onOptionChange"
          />
          <AppText color="var(--color-999999)" variant="div" ml="12px" mr="36px">
            Daily
          </AppText>
          <FSelect
            v-model="maxClientsAllowed"
            style="width: 140px;"
            :options="totalOptions"
            has-arrow
            no-margin
            size="x-small"
            @change="onOptionChange"
          />
          <AppText color="var(--color-999999)" variant="div" ml="12px">
            Total
          </AppText>
        </div>
      </RulesItem>

      <RulesItem
        icon="doc-bill-invoice"
        title="Subscription limits"
        description="Applicable only to new registrations"
      >
        <div class="d-flex align-items-center ">
          <FSelect
            v-model="maxDailySubscriptionsAllowed"
            style="width: 140px;"
            :options="dailyOptions"
            has-arrow
            no-margin
            size="x-small"
            @change="onOptionChange"
          />
          <AppText color="var(--color-999999)" variant="div" ml="12px" mr="36px">
            Daily
          </AppText>
          <FSelect
            v-model="maxSubscriptionsAllowed"
            style="width: 140px;"
            :options="totalOptions"
            has-arrow
            no-margin
            size="x-small"
            @change="onOptionChange"
          />
          <AppText color="var(--color-999999)" variant="div" ml="12px">
            Total
          </AppText>
        </div>
      </RulesItem>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import { changeRules, setAdminSettings } from '@/views/admin/AdminSettings/api';
import { useAdmin } from '@/composables/useAdmin';

import SettingsHeader from '../SettingsHeader.vue';

import RulesItem from './RulesItem.vue';

export default {
  name: 'AdminRules',
  components: {
    SettingsHeader,
    RulesItem,
  },
  setup() {
    const expiredRuleOptions = [
      { text: '24 hours', value: '24H' },
      { text: '48 hours', value: '48H' },
      { text: '72 hours', value: '72H' },
    ];

    const dailyOptions = [
      { text: '0', value: 0 },
      { text: '10', value: 10 },
      { text: '100', value: 100 },
      { text: 'Unlimited', value: 'unlimited' },
    ];

    const totalOptions = [
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '250', value: 250 },
      { text: '500', value: 500 },
      { text: '1000', value: 1000 },
      { text: 'Unlimited', value: 'unlimited' },
    ];

    const { adminSettings } = useAdmin();

    const paymentExpiredTime = ref(adminSettings.value.payments.expiredTime || 'unlimited');
    const maxClientsAllowed = ref(adminSettings.value.rules.maxClientsAllowed || 'unlimited');
    const maxDailyClientsAllowed = ref(adminSettings.value.rules.maxDailyClientsAllowed || 'unlimited');
    const maxSubscriptionsAllowed = ref(adminSettings.value.rules.maxSubscriptionsAllowed || 'unlimited');
    const maxDailySubscriptionsAllowed = ref(adminSettings.value.rules.maxDailySubscriptionsAllowed || 'unlimited');

    const onExpiredRuleChange = () => {
      setTimeout(() => {
        setAdminSettings({
          expiredTime: paymentExpiredTime.value,
        });
      }, 0);
    };

    const onOptionChange = () => {
      setTimeout(() => {
        const newRules = {
          total: {
            maxClientsLimit: maxClientsAllowed.value,
            maxSubscriptionsLimit: maxSubscriptionsAllowed.value,
          },
          daily: {
            maxClientsLimit: maxDailyClientsAllowed.value,
            maxSubscriptionsLimit: maxDailySubscriptionsAllowed.value,
          },
        };
        changeRules(newRules);
      }, 0);
    };

    return {
      expiredRuleOptions,

      dailyOptions,
      totalOptions,
      onExpiredRuleChange,

      paymentExpiredTime,
      maxClientsAllowed,
      maxDailyClientsAllowed,
      maxSubscriptionsAllowed,
      maxDailySubscriptionsAllowed,

      onOptionChange,
    };
  },
};
</script>

<style scoped lang="scss">
.rules {
  padding: 30px 0 0 40px;
  max-width: 760px;

  .rules-items-wrap {
    border: 1px solid var(--color-black-01);
    border-radius: 8px;
    margin-bottom: 40px;

    &>:first-child {
      border-bottom: 1px solid var(--color-black-01);
    }

    &>:last-child {
      border-bottom: none;
    }
  }
}
</style>

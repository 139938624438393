<template>
  <div class="coins-wrapper">
    <CoinsEnablerItem
      v-for="coin in coins"
      :key="coin.name"
      :coin="coin"
      @toggle="onToggle"
    >
      <template #icon>
        <div class="icon-wrapper">
          <i v-if="loadingIcon === coin.value" class="el-icon is-loading" style="width: 12px; height: 12px;">
            <Loading />
          </i>
          <AppCheckbox v-else type="round" size="small" :model-value="coin.isActive" />
        </div>
      </template>
    </CoinsEnablerItem>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { Loading } from '@element-plus/icons-vue';

import AppCheckbox from '@/components/AppCheckbox.vue';

import { fullCoinsArray, coinsSortWeight } from '@/common/data';
import { toggleCoinsAvailability } from '@/views/admin/AdminSettings/api';
import { useAdmin } from '@/composables/useAdmin';

import CoinsEnablerItem from './CoinsEnablerItem.vue';

const { adminSettings } = useAdmin();

const coins = computed(() => fullCoinsArray
  .map((coin) => {
    const isActive = adminSettings?.value?.availableCoins ? adminSettings.value.availableCoins[coin.value] : false;
    return { ...coin, isActive };
  })
  .sort((a, b) => coinsSortWeight[a.coin] - coinsSortWeight[b.coin]));

const loadingIcon = ref(null);
const onToggle = async (coin, value) => {
  const res = JSON.parse(JSON.stringify(adminSettings.value.availableCoins));
  res[coin] = value;

  loadingIcon.value = coin;
  await toggleCoinsAvailability(res);
  loadingIcon.value = null;
};
</script>

<style scoped lang="scss">
.coins-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .icon-wrapper {
    width: 15px;
    height: 15px;
    @include flex-center;
  }
}
</style>

<template>
  <div>
    <SettingsHeader
      title="Master Contract"
      description="Installed factory contracts for forwarder/clone contracts"
      style="margin-bottom: 20px;"
    />

    <TabsContainer
      v-model="activeTab"
      :options="tabs"
      type="card"
      style="margin-top: 20px;"
      :has-min-height="false"
    >
      <template #title="{ item }">
        <div class="d-flex w-100 align-items-center">
          <AppIcon :name="item.icon" size="24px" is-img-tag />
          <AppText variant="div" ml="10px" color="black" class="font-medium">
            {{ item.text }}
          </AppText>
        </div>
      </template>

      <div>
        <TooltipCopy is-icon-hidden class="w-100" :text="masterContractTextForCopy">
          <div class="container w-100">
            <div class="container-item">
              <AppText class="container-title">
                Contract Address:
              </AppText>
              <AppText> {{ masterContracts[activeTab].contractAddress }} </AppText>
            </div>
            <div class="container-item">
              <AppText class="container-title">
                EVM Address:
              </AppText>
              <AppText> {{ masterContracts[activeTab].address }} </AppText>
            </div>
            <div class="container-item">
              <AppText class="container-title">
                Contract Info:
              </AppText>
              <AppText> {{ masterContracts[activeTab].compiler }} </AppText>
            </div>
          </div>
        </TooltipCopy>
      </div>
    </TabsContainer>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';

import TooltipCopy from '@/components/TooltipCopy.vue';
import TabsContainer from '@/components/Containers/TabsContainer.vue';

import { supportedNetworksFamilyObj } from '@/common/data';
import { useAdmin } from '@/composables/useAdmin';

import SettingsHeader from '../SettingsHeader.vue';

export default defineComponent({
  components: {
    TooltipCopy,
    TabsContainer,
    SettingsHeader,
  },
  setup() {
    const tabs = supportedNetworksFamilyObj;
    const activeTab = ref(tabs[0].value);
    const { adminSettings } = useAdmin();

    const masterContracts = computed(() => adminSettings.value?.masterContracts);

    const masterContractTextForCopy = computed(() => `
Contract Address: ${masterContracts.value[activeTab.value].contractAddress}
EVM Address: ${masterContracts.value[activeTab.value].address}
Contract Info: ${JSON.stringify(masterContracts.value[activeTab.value].compiler)}`);

    return {
      tabs,
      activeTab,
      masterContracts,
      masterContractTextForCopy,
    };
  },
});
</script>

<style scoped lang="scss">
.container {
  border-radius: 6px;
  min-height: 100px;
  padding: 28px 24px;
}

.container-item {
  padding-bottom: 16px;
  margin-bottom: 16px;
  display: flex;
  border-bottom: 1px solid var(--color-E6E6E6);
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: unset;
  }
  .container-title {
    width: 118px;
    margin-right: 20px;
    @include font-medium;
  }
}

</style>
